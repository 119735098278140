export const SIGNUP = 'auth/sign-up';
export const SIGNIN = 'auth/sign-in';
export const FORGOT_PASSWORD = 'auth/forgot-password';

export const DELETE_ACCOUNT = 'auth/delete-account';

export const ACCOUNT_PROFILE = 'user/profile';
export const ACCOUNT_CHANGE_PASSWORD = 'user/change-password';

export const USER_NEWSLETTER_SUBSCRIBE = 'user/newsletter/subscribe';
export const USER_NEWSLETTER_UNSUBSCRIBE = 'user/newsletter/unsubscribe';

export const CATEGORIES = 'categories';
export const PRODUCTS = 'products';
export const PRODUCT = 'product';
export const PROMOS = 'promos';

export const WISHLIST = 'user/wishlist';
export const ADD_TO_CART = 'cart';
export const RETRIEVE_CART = 'cart';
export const INCREMENT_CART_ITEM_QTY = 'cart-item-increment-qty';
export const DECREMENT_CART_ITEM_QTY = 'cart-item-decrement-qty';
export const REMOVE_CART_ITEM = 'cart-item-remove';
export const CHECK_STOCK_LOCATIONS = 'check-stock-location';
export const CHECK_SHOWROOM_STOCK_AVAILABILITY = 'check-showroom-stock-availability';
export const CHECKOUT = 'checkout';
export const CHECKOUT_SHIPPING = 'checkout-shipping';
export const CHECKOUT_TRX = 'checkout-trx';
export const CHECKOUT_PAYMENT = 'checkout-payment';
export const CHECKOUT_PROMO_CODE = 'checkout-promo-code';

export const USER_ADDRESS = 'user/address';
export const USER_ADDRESS_SET_PRIMARY = 'user/address-primary';
export const USER_ORDERS = 'user/orders';
export const USER_REVIEWS = 'user/unreviewed-products';
export const USER_NOTIFICATIONS = 'user/notifications';
export const USER_NOTIFICATIONS_COUNT = 'user/unread-notifications-count';

export const MEMBER_INFO = 'membership/data';
export const MEMBER_EDIT = 'membership/data';
export const GET_VALID_MEMBER_TRX = 'membership/valid-member-trx';
export const POST_APPLY_MEMBERSHIP = 'membership/apply';

export const POST_CONFIRM_PAYMENT = 'membership/confirm-payment';
export const UPCOMING_EVENTS = 'event/upcoming';
export const REGISTER_TO_EVENT = 'event/register';
export const REGISTERED_EVENTS = 'event/registered';
export const POST_CONFIRM_EVENT_PAYMENT = 'event/confirm-payment';

export const MASTER_PROVINCE = 'master/province';
export const MASTER_CITY = 'master/city';
export const MASTER_SUBDISTRICT = 'master/subdistrict';
export const MASTER_SHOWROOM = 'master/showroom';
export const MASTER_SHOWROOM_REGION = 'master/showroom-region';
export const MASTER_PAGE_HOME = 'master/page/home';
export const MASTER_CATEGORY = 'category';
export const MASTER_CATEGORY_SUB = 'category-sub';



export const MASTER_OCCUPATION = 'master/occupation';
export const MASTER_LANG = 'master/lang';
export const MASTER_GALLERY = 'master/gallery';