import React from "react";

const modal = {
    position: "fixed",
    zIndex: 20,
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    overflow: "auto",
    backgroundColor: "rgba(0, 0, 0, 0.8)"
};

const close = {
    position: "absolute",
    top: 15,
    right: 35,
    color: "#f1f1f1",
    fontSize: 40,
    fontWeight: "bold",
    cursor: "pointer"
};

const modalContent = {
    display: "flex",
    alignItems: "center",
    width: "1024px",
    maxWidth: "80%",
    height: "100%",
    margin: "auto"
};

export const Modal = ({ onOpen, children }) => {
    return <div onClick={onOpen}> {children}</div>;
};

export const ModalContent = ({ onClose, children }) => {
    return (
        <div style={modal}>
            <span style={close} onClick={onClose}>
            &times;
            </span>
            <div style={modalContent}>{children}</div>
        </div>
    );
};
