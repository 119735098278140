import React from 'react';
import { Circle, CircleDot } from 'lucide-react';

const DeliveryTimeline = ({data}) => {
    return (
        <div style={{
            maxWidth: '100%',
            backgroundColor: '#f8f8f8',
            padding: '20px',
            borderRadius: '10px',
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
        }}>
            {/*<div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>*/}
            {/*    Riwayat Pengiriman*/}
            {/*</div>*/}
            {/*<div style={{ fontSize: '14px', color: '#777', marginBottom: '20px' }}>*/}
            {/*    Jumat, 11 Oct 2024*/}
            {/*</div>*/}

            <ul style={{ listStyle: 'none', padding: 0, margin: 0, position: 'relative' }}>
                {data.map((event, index) => (
                    <li key={index} style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '20px', position: 'relative' }}>
                        {/* Vertical line, shown only if it's not the last item */}
                        {index !== data.length - 1 && (
                            <div style={{
                                position: 'absolute',
                                top: '16px',
                                bottom: '-20px',
                                left: '8px',
                                width: '2px',
                                backgroundColor: '#ccc',
                            }} />
                        )}
                        <div style={{
                            marginRight: '10px',
                            display: 'flex',
                            alignItems: 'center',
                            color: event.status === 'DELIVERED' ? '#e74c3c' : '#ccc', // Red for DELIVERED, grey for others
                        }}>
                            {event.status === 'DELIVERED' ? <CircleDot size={16} /> : <Circle size={16} />}
                        </div>
                        <div style={{ fontSize: '14px' }}>
                            <div style={{
                                fontWeight: event.status === 'DELIVERED' ? 'bold' : 'normal',
                                color: event.status === 'DELIVERED' ? '#333' : '#555',
                            }}>
                                {event.time}
                            </div>
                            <div style={{ color: '#333', marginTop: '4px' }}>
                                {event.description}
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default DeliveryTimeline;