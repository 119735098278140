import React from "react";
import {Link} from "react-router-dom";
import styles from "./AccountMenu.module.scss";

const AccountMenu = ({activeMenu = '', isMobile = false}) => {

    const signOut = () => {
        if(window.confirm('Are you sure want to sign out?')){
            localStorage.clear();
            window.location.href = '/sign-in';
        }
    };

    return (
        <ul className={`${styles.menu} ${isMobile && styles.mobile}`}>
            <li className={activeMenu==='profile'?`active ${styles.active}`:''}><Link to="/account">Account Information</Link></li>
            <li className={activeMenu==='orders'?`active ${styles.active}`:''}><Link to="/account/orders">My Orders</Link></li>
            {/*<li className={activeMenu==='reviews'?`active ${styles.active}`:''}><Link to="/account/reviews">My Reviews</Link></li>*/}
            <li className={activeMenu==='wishlist'?`active ${styles.active}`:''}><Link to="/account/wishlist">My Wishlists</Link></li>
            <li className={activeMenu==='shipping-address'?`active ${styles.active}`:''}><Link to="/account/shipping-address">Shipping Addresses</Link></li>
            <li className={activeMenu==='change-password'?`active ${styles.active}`:''}><Link to="/account/change-password">Change Password</Link></li>
            <li className="last"><a href="javascript:;" onClick={signOut}>Sign Out</a></li>
        </ul>
    );
};
export default AccountMenu;